export class Claim {
  id: number;
  assessorId: number;
  assessorName: string;
  claimantId: number;
  claimantName: string;
  claimLevelId: number;
  claimLevelName: string;
  competencyDimensionId: number;
  competencyDimensionName: string;
  createDatetime: string;
  disciplineId: number;
  disciplineName: string;
  expiryDate: string;
  isOpen: boolean;
  isPrivate: boolean;
  isPublished: boolean;
  lastActionDatetime: string;
  lastActionName: string;
  rowVersion: number;
  selfAssessed: boolean;
  skillId: number;
  skillName: string;
  status: string;
  statusId: number;
  statusText: string;
  hasNotification: boolean;

  canEdit() {
    // console.log(`!this.isOpen: ${!this.isOpen}, !this.isPrivate: ${!this.isPrivate}`);
    // console.log(`!(!this.isOpen || !this.isPrivate): ${!(!this.isOpen || !this.isPrivate)}`);
    // return !(!(!this.isOpen || !this.isPrivate)); // TODO: WTF?!?!
    return (
      (this.isOpen === undefined || this.isOpen) &&
      (this.isPrivate === undefined || this.isPrivate)
    );
  }
}
